<template>
    <div class="OperationRide" :class="{ 'is-loading': isFetchingData }">
        <div class="OverviewCards">
            <Card variant="info">
                <p>Schichten mit 6h oder mehr</p>
                <h3>{{ shiftsWith6hOrMore }}</h3>
            </Card>
            <Card variant="info">
                <p>Unangemeldet Schichten</p>
                <h3>{{ shiftsWithoutRegistration }}</h3>
            </Card>
            <Card variant="info">
                <p>Gefahrene Km (ohne Privat-Km)</p>
                <h3>{{ distanceToString(totalKmWithoutPrivate) }}</h3>
            </Card>
            <Card variant="info">
                <p>Gefahrene Km (mit Privat-Km)</p>
                <h3>{{ distanceToString(totalKmWithPrivate) }}</h3>
            </Card>
            <Card variant="info">
                <p>Privat Km</p>
                <h3>{{ distanceToString(totalPrivateKm) }}</h3>
            </Card>
            <Card variant="info">
                <p>Besetzt / Gesamt-KM (mit Privat)</p>
                <h3>{{ percentHiredKm }}</h3>
            </Card>
        </div>
        <Toolbar>
            <div>
                <Dropdown
                    hasSearch
                    :items="cars"
                    :selected="selectedCar"
                    placeholder="Kennzeichen"
                    @onItemSelect="handleCarSelect"
                />
                <Datepicker
                    @onChange="handleDateChange"
                    :startDate="selectedFrom"
                    :endDate="selectedTo"
                />
                <Button
                    size="small"
                    @onClick="handleFetchOperatingRecords"
                    :isLoading="isFetchingData"
                >
                    Anzeigen
                </Button>
                <div class="ToolbarActions--Group">
                    <Button
                        v-if="selectedCar"
                        v-tooltip="'In Umsatz Anzeigen'"
                        variant="icon"
                        @onClick="handleShowOnPage('revenue')"
                    >
                        <i class="ri-money-dollar-circle-line"></i>
                    </Button>
                    <Button
                        v-if="selectedCar"
                        v-tooltip="'In Einzelfahrten Anzeigen'"
                        variant="icon"
                        @onClick="handleShowOnPage('trips')"
                    >
                        <i class="ri-steering-2-line"></i>
                    </Button>
                    <Button
                        v-if="selectedCar"
                        v-tooltip="'In Schichten Anzeigen'"
                        variant="icon"
                        @onClick="handleShowOnPage('shifts')"
                    >
                        <i class="ri-repeat-line"></i>
                    </Button>
                </div>
            </div>
            <div>
                <!-- <Download title="Download PDF" type="pdf" @onDownload="handleDownload" /> -->
                <Download title="Download CSV" type="csv" @onDownload="handleDownload" />
            </div>
        </Toolbar>

        <operation-ride-table-car
            v-if="showSingleCar"
            :operationRecords="operationRecords"
            @onSumOfAllGaps="handleSumOfAllGaps"
        />
        <operation-ride-table
            v-else
            :operationRecords="operationRecords"
            @onSumOfAllGaps="handleSumOfAllGaps"
        />
    </div>
</template>

<script>
import {
    endOfDay,
    format,
    formatISO,
    isValid,
    parseISO,
    startOfDay,
    startOfMonth,
    subDays,
} from 'date-fns';
import axios from 'axios';
import Button from '@/components/widgets/Button';
import Datepicker from '@/components/widgets/Datepicker';
import Download from '@/components/widgets/Download';
import Dropdown from '@/components/widgets/Dropdown';
import Headline from '@/components/Headline';
import Toolbar from '@/components/Toolbar';
import OperationRideTable from './components/OperationRideTable';
import OperationRideTableCar from './components/OperationRideTableCar';
import { distanceToString, priceToEuroString, round2d } from '@/lib/helper';
import Card from '@/components/Card.vue';

export default {
    name: 'OperationRide',
    components: {
        Card,
        Button,
        Datepicker,
        Download,
        Dropdown,
        Headline,
        Toolbar,
        OperationRideTable,
        OperationRideTableCar,
    },
    data: () => ({
        distanceToString,
        isFetchingData: false,
        operationRecords: [],
        shiftsWithoutRegistration: 0,
        drivers: JSON.parse(localStorage.getItem('drivers')).filter(d => d.isVisible),
        cars: JSON.parse(localStorage.getItem('cars')),
        selectedCar: null,
        showSingleCar: false,
        selectedFrom: startOfMonth(new Date()),
        selectedTo: new Date(),
    }),
    computed: {
        shiftsWith6hOrMore() {
            return this.operationRecords.filter(
                r =>
                    new Date(r.endAt).getTime() - new Date(r.startAt).getTime() >=
                    6 * 60 * 60 * 1000,
            ).length;
        },

        totalKmWithoutPrivate() {
            return this.operationRecords.reduce(
                (sum, record) => sum + (record.mileageEnd - record.mileageStart),
                0,
            );
        },

        totalKmWithPrivate() {
            if (!this.operationRecords.length) return '0 km';

            const grouped = this.operationRecords.reduce((acc, r) => {
                const key = r.licenseNumber;

                if (!acc[key]) {
                    acc[key] = { minMileageStart: r.mileageStart, maxMileageEnd: r.mileageEnd };
                } else {
                    acc[key].minMileageStart = Math.min(acc[key].minMileageStart, r.mileageStart);
                    acc[key].maxMileageEnd = Math.max(acc[key].maxMileageEnd, r.mileageEnd);
                }

                return acc;
            }, {});

            const totalDistance = Object.values(grouped).reduce(
                (sum, group) => sum + (group.maxMileageEnd - group.minMileageStart),
                0,
            );

            return totalDistance;
        },
        totalPrivateKm() {
            if (!this.operationRecords.length) return 0;
            // Accumulate private kilometers
            if (!this.totalKmWithPrivate || !this.totalKmWithPrivate) return 0;
            return this.totalKmWithPrivate - this.totalKmWithoutPrivate;
        },
        percentHiredKm() {
            if (!this.operationRecords.length) return '0%';

            const grouped = this.operationRecords.reduce((acc, r) => {
                const key = r.licenseNumber;

                if (!acc[key]) {
                    acc[key] = {
                        minMileageStart: r.mileageStart,
                        maxMileageEnd: r.mileageEnd,
                        totalHired: r.hiredDistance,
                    };
                } else {
                    acc[key].minMileageStart = Math.min(acc[key].minMileageStart, r.mileageStart);
                    acc[key].maxMileageEnd = Math.max(acc[key].maxMileageEnd, r.mileageEnd);
                    acc[key].totalHired += r.hiredDistance;
                }

                return acc;
            }, {});

            let totalHiredKm = 0;
            let totalKmWithPrivate = 0;

            Object.values(grouped).forEach(group => {
                totalKmWithPrivate += group.maxMileageEnd - group.minMileageStart;
                totalHiredKm += group.totalHired;
            });

            const percentage = totalKmWithPrivate ? (totalHiredKm / totalKmWithPrivate) * 100 : 0;

            return `${percentage.toFixed(2)}%`;
        },
    },
    methods: {
        handleShowOnPage(path) {
            const { selectedFrom, selectedTo, selectedCar, selectedEmployee } = this;
            const params = {
                from: format(selectedFrom, 'yyyy-MM-dd'),
                to: format(selectedTo, 'yyyy-MM-dd'),
                car: selectedCar?.value,
                driver: selectedEmployee?.value,
            };
            this.$router.push({ name: path, query: params });
        },
        handleSumOfAllGaps(sum) {
            this.shiftsWithoutRegistration = distanceToString(sum);
        },
        handleDateChange(daterange) {
            this.selectedFrom = daterange.from;
            this.selectedTo = daterange.to;
        },
        handleCarSelect({ item }) {
            this.selectedCar = item;
        },
        handleDownload(type) {
            if (type === 'pdf') {
            } else if (type === 'csv') {
                const csv = this.operationRecords.map(r => {
                    return {
                        Fahrer: r.driver?.name,
                        Kennzeichen: r.licenseNumber,

                        Beginn: format(new Date(r.startAt), 'dd.MM.yyyy HH:mm'),
                        Ende: format(new Date(r.endAt), 'dd.MM.yyyy HH:mm'),
                        Dauer: `${Math.floor(
                            (new Date(r.endAt).getTime() - new Date(r.startAt).getTime()) /
                                (1000 * 60 * 60),
                        )}h ${Math.floor(
                            ((new Date(r.endAt).getTime() - new Date(r.startAt).getTime()) /
                                (1000 * 60 * 60)) %
                                60,
                        )}m`,
                        'KM Abfahrt': distanceToString(r.mileageStart),
                        'KM Ankunft': distanceToString(r.mileageEnd),
                        'Gesamt KM': distanceToString(r.totalDistance),
                        'Besetzt KM': distanceToString(r.hiredDistance),
                        Touren: r.tripsCount,
                        'Eur/KM': priceToEuroString(
                            r.finalTotalAmount / Math.max(r.totalDistance / 1000, 1),
                        ),
                        'Umsatz (€)': round2d(r.finalTotalAmount / 100).format(),
                    };
                });
                const csvString = `${Object.keys(csv[0]).join(';')}\n${csv
                    .map(r => Object.values(r).join(';'))
                    .join('\n')}`;
                const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);

                const filename = `Schichtbericht_${this.selectedCar?.name || ''}_${format(
                    this.selectedFrom,
                    'dd.MM.yyyy',
                )}_${format(this.selectedTo, 'dd.MM.yyyy')}.csv`;
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },
        async handleFetchOperatingRecords() {
            this.isFetchingData = true;
            const queryData = {
                licenseNumber: this.selectedCar ? this.selectedCar.id : null,
                rangeStartAt: formatISO(this.selectedFrom),
                rangeEndAt: this.selectedTo ? formatISO(endOfDay(this.selectedTo)) : null,
            };
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/shifts`;
                const result = await axios.post(url, queryData, {
                    withCredentials: true,
                });
                this.$emit('onFinishLoading');
                this.operationRecords = result.data;
                this.showSingleCar = this.selectedCar?.id;
            } catch (error) {
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isFetchingData = false;
            }
        },
    },
    mounted() {
        console.log('mounted operation ride');
        const query = this.$route.query;
        const { from, to, car, driver } = query;
        if (from && isValid(parseISO(from))) {
            this.selectedFrom = parseISO(from);
        }
        if (to && isValid(parseISO(to))) {
            this.selectedTo = parseISO(to);
        }
        if (car) {
            this.selectedCar = this.cars.find(c => c.value === car) || null;
        }
        if (driver) {
            this.selectedEmployee = this.drivers.find(e => e.value === driver) || null;
        }
        setTimeout(() => {
            this.handleFetchOperatingRecords();
        }, 100);
    },
};
</script>

<style lang="scss">
.OperationRide {
    @extend %contentWrapper;
    @extend %page;

    .OverviewCards {
        margin: 0px 0 40px;
    }
}
</style>
