<template>
    <div class="Toolbar">
        <Button class="MenuIcon" size="small" variant="plain" @onClick="toggleMenu">
            <div class="MenuIcon">
                <div class="Hamburger">
                    <div class="Line"></div>
                    <div class="Line"></div>
                    <div class="Line"></div>
                </div>
                Optionen
            </div>
        </Button>
        <div class="ToolbarItems" :class="{ 'is-visible': isMenuOpen }">
            <slot />
        </div>
        <div class="Backdrop" :class="[{ 'is-visible': isMenuOpen }]" @click="toggleMenu"></div>
    </div>
</template>

<script>
import Button from '@/components/widgets/Button.vue';

export default {
    components: { Button },
    data() {
        return {
            isMenuOpen: false,
        };
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
    },
};
</script>

<style lang="scss">
.Toolbar {
    margin-bottom: 40px;
    z-index: 3;
    position: relative;
    grid-template-columns: 1fr auto;
    display: grid;
    gap: 10px;

    & + .Toolbar {
        margin-top: -20px;
        margin-bottom: 20px;
    }

    @media screen and (max-width: breakpoint(tabletPortrait)) {
        display: inline-flex;
        z-index: 21;
    }

    .MenuIcon {
        font-size: 14px;
        cursor: pointer;
        display: none;

        .Hamburger {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
            margin-right: 4px;
        }

        .Line {
            background-color: var(--color-text-black);
            height: 2px;
            width: 18px;
        }

        @media screen and (max-width: breakpoint(tabletPortrait)) {
            display: flex;
            align-items: center;
        }
    }

    .ToolbarItems {
        gap: 4px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        transition: all 0.3s ease;
        opacity: 1;
        visibility: visible;

        @media screen and (max-width: breakpoint(tabletPortrait)) {
            flex-direction: column;
            align-items: flex-start;
            min-width: unset;
            gap: 8px;
            overflow: hidden;
            background: var(--color-bg);
            border: solid 1px var(--color-border);
            z-index: 100;
            z-index: 100;
            padding: 20px;
            position: fixed;
            border-radius: 10px;
            opacity: 0;
            visibility: hidden;

            &.is-visible {
                opacity: 1;
                visibility: visible;
                transition: max-height 0.3s ease;
            }
        }

        > div {
            display: flex;
            gap: 4px;
            grid-auto-flow: column;

            @media (max-width: 1200px) {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: baseline;
            }

            @media screen and (max-width: breakpoint(tabletPortrait)) {
                gap: 8px;
            }
        }
    }

    .ToolbarActions--Group {
        margin-left: 10px;
        display: flex;
        gap: 4px;

        @media (max-width: 1200px) {
            margin-left: 0px;
        }
    }

    .Backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        z-index: 2;
        transition: all 0.3s ease;
        pointer-events: none;
        opacity: 0;
        visibility: hidden;

        &.is-visible {
            opacity: 1;
            pointer-events: auto;
            visibility: visible;
        }
    }
}
</style>
