here is how privat rides are acc:
<template>
    <div>
        <!-- Table Container -->
        <RowWrap :class="{ 'is-empty': mOperationRecords.length === 0 }">
            <!-- Header -->
            <template #header>
                <Row
                    :items="{ row: header }"
                    :key="-1"
                    :index="-1"
                    class="Row-Header"
                    @onClickColumn="handleHeaderClick"
                />
            </template>
            <Row
                v-for="(operationRecord, i) in mOperationRecords"
                :key="i"
                :index="i"
                isDropdown
                :parentHover="true"
                :items="operationRecord"
            >
                <template #default>
                    <Row
                        v-for="(child, childIndex) in operationRecord.children"
                        :key="childIndex"
                        :items="child.row"
                    />
                </template>
            </Row>
            <EmptyState v-if="mOperationRecords.length === 0" />
        </RowWrap>
        <Button
            v-if="
                mOperationRecords.length >= 120 &&
                    mOperationRecords.length < operationRecords.length
            "
            class="Button--LoadMore"
            @onClick="handleLoadMore"
        >
            Mehr Laden
        </Button>
    </div>
</template>
<script>
import Row from '@/components/Row';
import Button from '@/components/widgets/Button';
import EmptyState from '@/components/EmptyState.vue';
import RowWrap from '@/components/RowWrap.vue';
import { priceToEuroString, distanceToString } from '@/lib/helper';

export default {
    name: 'ItemTable',
    components: {
        RowWrap,
        Row,
        Button,
        EmptyState,
    },
    props: {
        operationRecords: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            cursor: 120, // Pagination cursor
            sortState: {
                column: 'Kennzeichen',
                ascending: true,
            },
        };
    },
    computed: {
        /**
         * Builds the table header with sorting indicators
         */
        header() {
            return [
                'Kennzeichen',
                'Gefahrene Km (ohne privat)',
                'Gefahrene Km (mit privat)',
                'Privat Km',
                'Besetzt Km',
                'Leer Km',
                'Umsatz (€)',
                '€ / Km',
                'Touren',
                'Schichten mit 6h+', // New column
                'Besetzt / Gesamt-KM (mit Privat-KM)',
            ].map(name => ({
                name: `${name}${
                    this.sortState.column === name ? (this.sortState.ascending ? ' ▲' : ' ▼') : ''
                }`,
                value: null,
            }));
        },
        /**
         * Filters, groups, and formats operation records for the table
         */
        mOperationRecords() {
            const groupedRecords = this.groupAndAggregate(this.operationRecords);
            const sortedRecords = this.sortOperationRecords(groupedRecords);
            return sortedRecords.slice(0, this.cursor);
        },
    },
    methods: {
        /**
         * Handles sorting when a table header is clicked
         */
        handleHeaderClick(column) {
            const columnName = column.name.replace(/ ▲| ▼/, '').trim();
            if (this.sortState.column === columnName) {
                this.sortState.ascending = !this.sortState.ascending;
            } else {
                this.sortState.column = columnName;
                this.sortState.ascending = true;
            }
        },
        sortOperationRecords(records) {
            const { column, ascending } = this.sortState;
            return records.sort((a, b) => {
                const aVal = a.row.find(item => item.name === column)?.rawValue || '';
                const bVal = b.row.find(item => item.name === column)?.rawValue || '';
                if (typeof aVal === 'number' && typeof bVal === 'number') {
                    return ascending ? aVal - bVal : bVal - aVal;
                }
                if (typeof aVal === 'string' && typeof bVal === 'string') {
                    const comparison = aVal.localeCompare(bVal);
                    return ascending ? comparison : -comparison;
                }
                return 0;
            });
        },
        groupAndAggregate(records) {
            const grouped = records.reduce((acc, record) => {
                const key = record.licenseNumber;
                // Initialize the group if it doesn't exist
                if (!acc[key]) {
                    acc[key] = {
                        licenseNumber: key,
                        minMileageStart: record.mileageStart, // Earliest mileageStart
                        maxMileageEnd: record.mileageEnd, // Latest mileageEnd
                        hiredDistance: 0, // Total Besetzt Km
                        kmWithoutPrivate: 0, // Total driven within shifts
                        finalTotalAmount: 0, // Total revenue
                        tripsCount: 0, // Number of trips
                        shifts6hOrMore: 0, // Number of shifts lasting 6 hours or more
                    };
                }
                // Compare and update minMileageStart and maxMileageEnd
                acc[key].minMileageStart = Math.min(acc[key].minMileageStart, record.mileageStart);
                acc[key].maxMileageEnd = Math.max(acc[key].maxMileageEnd, record.mileageEnd);
                // Accumulate values
                acc[key].hiredDistance += record.hiredDistance; // Add Besetzt Km
                acc[key].kmWithoutPrivate += record.mileageEnd - record.mileageStart; // Add Km within shifts
                acc[key].finalTotalAmount += record.finalTotalAmount; // Add revenue
                acc[key].tripsCount += record.tripsCount; // Add number of trips
                // Count shifts lasting 6 hours or more
                const shiftDurationMs =
                    new Date(record.endAt).getTime() - new Date(record.startAt).getTime();
                if (shiftDurationMs >= 6 * 60 * 60 * 1000) {
                    acc[key].shifts6hOrMore += 1;
                }
                return acc;
            }, {});
            // Convert grouped data into an array
            return Object.values(grouped).map(group => ({
                row: [
                    {
                        name: 'Kennzeichen',
                        value: group.licenseNumber,
                        rawValue: group.licenseNumber,
                    },
                    {
                        name: 'Gefahrene Km (ohne privat)',
                        value: distanceToString(group.kmWithoutPrivate),
                        rawValue: group.kmWithoutPrivate,
                    },
                    {
                        name: 'Gefahrene Km (mit privat)',
                        value: distanceToString(group.maxMileageEnd - group.minMileageStart),
                        rawValue: group.maxMileageEnd - group.minMileageStart,
                    },
                    {
                        name: 'Privat Km',
                        value: distanceToString(
                            group.maxMileageEnd - group.minMileageStart - group.kmWithoutPrivate,
                        ),
                        rawValue:
                            group.maxMileageEnd - group.minMileageStart - group.kmWithoutPrivate,
                    },
                    {
                        name: 'Besetzt Km',
                        value: distanceToString(group.hiredDistance),
                        rawValue: group.hiredDistance,
                    },
                    {
                        name: 'Leer Km',
                        value: distanceToString(
                            group.maxMileageEnd - group.minMileageStart - group.hiredDistance,
                        ),
                        rawValue: group.maxMileageEnd - group.minMileageStart - group.hiredDistance,
                    },
                    {
                        name: 'Umsatz (€)',
                        value: priceToEuroString(group.finalTotalAmount),
                        rawValue: group.finalTotalAmount,
                    },
                    {
                        name: '€ / Km',
                        value: priceToEuroString(
                            group.finalTotalAmount /
                                Math.max((group.maxMileageEnd - group.minMileageStart) / 1000, 1),
                        ),
                        props: {
                            style: {
                                flex: 1,
                            },
                        },
                        rawValue:
                            group.finalTotalAmount /
                            Math.max((group.maxMileageEnd - group.minMileageStart) / 1000, 1),
                    },
                    {
                        name: 'Touren',
                        value: group.tripsCount,
                        rawValue: group.tripsCount,
                    },
                    {
                        name: 'Schichten mit 6h+',
                        value: group.shifts6hOrMore,
                        rawValue: group.shifts6hOrMore,
                    },
                    {
                        name: 'Besetzt / Gesamt-KM (mit Privat-KM)',
                        value: `${(
                            (group.hiredDistance / (group.maxMileageEnd - group.minMileageStart)) *
                                100 || 0
                        ).toFixed(2)}%`,
                        rawValue:
                            group.hiredDistance / (group.maxMileageEnd - group.minMileageStart),
                    },
                ],
                data: group, // Attach raw group data (optional, for debugging or additional use cases)
            }));
        },
        handleLoadMore() {
            this.cursor += 120;
        },
    },
};
</script>
<style lang="scss" scoped>
.Row-Wrap {
    width: 100%;
    position: sticky;
    top: 0;

    > * {
        min-width: 1000px;
    }

    &.is-empty {
        > * {
            min-width: 0;
        }
    }

    .Button {
        display: block;
        margin: 30px auto !important;
    }
}
</style>
