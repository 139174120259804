import currency from 'currency.js';
import { format } from 'date-fns';

export const euro = (value, options) => {
    return currency(value, {
        symbol: '',
        separator: '.',
        decimal: ',',
        fromCents: true,
        ...options,
    });
};
export const round2d = value => {
    return currency(value, { symbol: '', separator: '.', decimal: ',', fromCents: false });
};

export const priceToEuroString = price => {
    return euro(price).format() + ' €';
};

export const distanceToString = (distance, unit, suffix = 'km') => {
    if (unit === 'km') {
        return `${round2d(distance)
            .format()
            .replace(',00', '')}${suffix}`;
    }
    return `${round2d(distance / 1000).format()}${suffix}`;
};

export const percentageToString = percentage => {
    return `${round2d(percentage * 100).format()} %`;
};

export const timeToString = (time, options = {}) => {
    const { withLabel } = options;

    // First, calculate the number of full days (86400 seconds in a day)
    const days = Math.floor(time / 86400);
    const remainderAfterDays = time % 86400;

    // Calculate hours from the remainder
    const hours = Math.floor(remainderAfterDays / 3600);

    // Calculate minutes from the remainder
    const minutes = Math.floor((remainderAfterDays % 3600) / 60);

    if (withLabel) {
        // Return formatted string with labels: 'xh ym' (e.g.: '2d 5h 34m')
        const dayPart = days > 0 ? `${days}d ` : '';
        const hourPart = `${hours}h `;
        const minutePart = `${minutes}m`;

        return `${dayPart}${hourPart}${minutePart}`.trim();
    } else {
        // Return formatted string in 'D H:M' format (e.g.: '2 05:34' or '05:34' when no days)
        const dayPart = days > 0 ? `${days} ` : '';
        const hourPart = hours.toString().padStart(2, '0');
        const minutePart = minutes.toString().padStart(2, '0');

        return `${dayPart}${hourPart}:${minutePart}`.trim();
    }
};

export const dateToString = date => {
    if (!date) {
        return '';
    }
    return format(new Date(date), 'dd.MM.yyyy');
};

export const getDuration = seconds => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds - hours * 3600) / 60);

    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }

    return `${hours}:${minutes}`;
};
